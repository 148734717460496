import React from 'react'
import { IconBackground } from '../components/IconBackground'
import Layout from '../components/mdxLayout'

export default () => (
  <Layout>
    <>
      <div className="bg-grape">
        <div className="relative overflow-hidden">
          <div className="text-white opacity-10">
            <IconBackground/>
          </div>

          <div className="relative pb-16 sm:pb-18">
            <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
              <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                  Careers
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </Layout>
)
